<template>
  <div class="countBox">
    <button @click="handleSub"  class="minus">-</button>
    <input @change="handleChange" :value="value" class="inp" type="text" />
    <button @click="handleAdd" class="add">+</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    handleSub () {
      if (this.value <= 1) return
      this.$emit('input', this.value - 1)
    },
    handleAdd () {
      this.$emit('input', this.value + 1)
    },
    handleChange (e) {
      const num = +e.target.value
      if (isNaN(num)) {
        e.target.value = this.value
        return
      }
      this.$emit('input', parseInt(num))
    }
  }
}
</script>

<style lang="less" scoped>
.countBox {
  width: 110px;
  display: flex;
  .add, .minus {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    background-color: #efefef;
  }
  .inp {
    text-align: center;
    width: 40px;
    height: 30px;
    outline: none;
    border: none;
    margin: 0 5px;
    background-color: #efefef;
  }
}
</style>
