export default {
  // 此处编写vue组件实例的配置项
  created () {},
  data () {
    return {
    }
  },
  methods: {
    loginConfirm () {
      if (!this.$store.getters.token) {
        // console.log('弹出确认框')
        this.$dialog
          .confirm({
            title: '温馨提示',
            message: '您还未登录，是否前往登录？',
            confirmButtonText: '登录',
            cancelButtonText: '再逛逛'
          })
          .then(() => {
            console.log('前往登录')
            this.$router.replace({
              path: '/login',
              query: {
                backUrl: this.$route.fullPath
              }
            })
          })
          .catch(() => {})
        return true
      }
      return false
    }
  }
}
