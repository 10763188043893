<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
      checked: true,
      score: 3
    }
  }
}
</script>

<style lang="less">

</style>
